import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../pages/home/homepages";
import ProtectedRoute from "./ProtectedRoute";
import About from "../pages/About/About";
import Product from "../pages/product/Product";
import ViewProduct from "../pages/product/ViewProduct";
import Contactus from "../pages/ContactUs/Contactus";
import Contruction from "../pages/UnderContruction/Contruction";

export default function CustomRoute() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <HomePage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/about"
        element={
          <ProtectedRoute>
            <About />
          </ProtectedRoute>
        }
      />
      <Route
        path="/product"
        element={
          <ProtectedRoute>
            <Product />
          </ProtectedRoute>
        }
      />
      <Route
        path="/product/viewProduct"
        element={
          <ProtectedRoute>
            <ViewProduct />
          </ProtectedRoute>
        }
      />
      <Route
        path="/contactUs"
        element={
          <ProtectedRoute>
            <Contactus />
          </ProtectedRoute>
        }
      />
      {/* <Route
        path="/"
        element={
          <ProtectedRoute>
            <Contruction />
          </ProtectedRoute>
        }
      /> */}
    </Routes>
  );
}
