import React from "react";
const About = () => {
  return (
    <div>
      <div className="maincontent">
        <div className="text-content-image  bg-white before:bg-white py-[1.5rem] lg:py-[6.5rem]">
          <div className="container px-[16px] fade-up fade-up-active m-auto">
            <div className="grid grid-cols-1  md:grid-cols-2 gap-5">
              <div className="text-content-image-visual fade-up fade-up-active relative overflow-hidden h-[200px] md:h-[260px]  block lg:hidden">
                <img
                  src={require("../../images/Somfy_careers_jobs3.jpg")}
                  alt=""
                  className="absolute top-0 left-0 h-full w-full  rounded-2xl object-cover"
                />
              </div>
              <div className="text-content-image-wrapper  lg:ml-[24%] text-[#2B403B] flex items-center">
                <div>
                  <h2 className="text-content-image-title text-[1.7rem] lg:text-[2.7rem] font-[500] lg:mb-6">
                    Your future at Decode
                  </h2>
                  <h4 className="text-content-image-subtitle text-[1rem] lg:text-[1.68rem] font-[500] lg:leading-7 mb-2 lg:mb-4">
                    Discover all the opportunities offered by an international
                    group.
                  </h4>
                  <div className="text-content-image-content text-[12px] lg:text-sm font-[500] mb-5 lg:mb-14">
                    <p>
                      Find all Decode offers and all the other offers of the
                      Group on our Careers page.
                    </p>
                  </div>
                  <button className="rounded-full border-[1px] bg-[#2B403B] text-center px-3 py-3 box-border lg:px-5 lg:py-5">
                    <span className="text-[#ffff] font-[600] text-[12px] lg:text-[16px]">
                      Apply Today
                    </span>
                  </button>
                </div>
              </div>
              <div
                className="text-content-image-visual fade-up fade-up-active relative overflow-hidden rounded-tl-[31.25rem] rounded-bl-[31.25rem] h-[470px] ml-[70px] lg:ml-[36px] hidden lg:block"
                style={{ width: "calc(100% + -2.8vw)" }}
              >
                <img
                  src={require("../../images/Somfy_careers_jobs3.jpg")}
                  alt=""
                  className="absolute top-0 right-0 h-full w-full object-cover"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="benefits-icons bg-white before:bg-white lg:pt-[3rem] ">
          <div className="container m-auto px-[16px] lg:px-[10vw]">
            <div className="heder-content mb-[2.5rem] lg:mb-[3.5rem]">
              <h4 className="row-head-headtitle -headtitle h6 text-[1.31rem] font-[500] text-[#2B403B]">
                Would you like to join our team?
              </h4>
              <h2 className="row-head-title text-[1.7rem] mb-2 lg:mb-6 lg:text-[2.93rem] font-[500] text-[#2B403B]">
                Discover the available opportunities
              </h2>
              <h3 className="row-head-subtitle   text-[1rem] lg:mb-6 lg:text-[2.25rem] font-[500] text-[#2B403B] ">
                In India or around the world
              </h3>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
              <div className="flex flex-col p-[1.75rem] rounded-xl   border-4 border-[#F1EDE8] ">
                <i className="fa-solid fa-user-group text-[#2B403B] text-[2.5rem] mb-[1.75rem]"></i>
                <h4 className="benefit-title text-[1.31rem] font-[500] mb-[1rem] text-[#2B403B]">
                  Jobs at Decode India
                </h4>
                <span className="mt-5 text-[#ffff] font-[600] bg-[#2B403B] px-3 py-1 md:py-2 lg:px-4 lg:py-3 text-[14px] md:text-[16px] rounded-full border-[1px] text-center">
                  <span className="">Consult the list of vacancies</span>
                </span>
              </div>
              <div className="flex flex-col p-[1.75rem] rounded-xl   border-4 border-[#F1EDE8] ">
                <i className="fa-solid fa-globe text-[#2B403B] text-[2.5rem] mb-[1.75rem]"></i>
                <h4 className="benefit-title text-[1.31rem] font-[500] mb-[1rem] text-[#2B403B]">
                  Job at Decode Group
                </h4>
                <span className="mt-5 text-[#ffff] font-[600] bg-[#2B403B] px-3 py-1 md:py-2 lg:px-4 lg:py-3 text-[14px] md:text-[16px] rounded-full border-[1px] text-center">
                  <span className="">Find Job</span>
                </span>
              </div>
              <div className="flex flex-col p-[1.75rem] rounded-xl   border-4 border-[#F1EDE8] ">
                <i className="fa-solid fa-handshake text-[#2B403B] text-[2.5rem] mb-[1.75rem]"></i>
                <h4 className="benefit-title text-[1.31rem] font-[500] mb-[1rem] text-[#2B403B]">
                  Jobs at Decode Team
                </h4>
                <span className="mt-5 text-[#ffff] font-[600] bg-[#2B403B] px-3 py-1 md:py-2 lg:px-4 lg:py-3 text-[14px] md:text-[16px] rounded-full border-[1px] text-center">
                  <span className="">Discover our corporate culture</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="video-with-intro py-[2rem] lg:py-[7.5rem]">
          <div className="container px-[16px] md:px-20 m-auto">
            <div className="flex justify-center">
              <div
                className="relative  rounded-lg w-[850px]"
                // style={{
                //   padding: "calc(30.25% - 4px)",
                // }}
              >
                <img
                  src={require("../../images/Somfy_headquarter_cluses.jpg")}
                  className="relative rounded-[20px] top-0 left-0   h-[15rem] md:h-[18rem] lg:h-[30rem] w-full"
                  alt=""
                />
                <button
                  type="button"
                  style={{ background: "white" }}
                  className=" absolute bg-white text-[#2B403B] shadow-sm inline-flex items-center justify-center align-middle text-center  font-medium text-base leading-6 rounded-[30px] no-underline whitespace-no-wrap max-w-full shadow-white top-[45%] left-[42%] md:left-[45%] md:top-[45%] lg:left-[46%] lg:top-[45%] px-4 py-2 md:py-2 lg:px-4 lg:py-3
                  text-[14px] md:text-[16px]"
                >
                  Play
                </button>
              </div>
            </div>
            <div className="player-video-title mt-2 text-[#2B403B] font-[600] flex justify-center text-lg">
              Decode Group
            </div>
          </div>
        </div>
        <div className=" bg-white before:bg-white ">
          <div className="container px-[16px] lg:px-[10vw] m-auto">
            <div className="grid grid-cols-1 lg:grid-cols-4">
              <div className="policy mb-[1rem] lg:mb-[3.5rem] col-span-4 lg:col-span-3">
                <p className="text-[1rem] lg:text-[2.93rem] font-[500] text-[#2B403B] leading-6lg:leading-[50px]">
                  We pay particular attention to the security of your personal
                  data
                </p>
                <h2 className="text-[1.5rem] lg:text-[3.81rem] font-[500] text-[#2B403B]">
                  Your personal data are secure
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className=" bg-white before:bg-white mb-10 lg:mb-36 md:mt-5">
          <div className="container m-auto px-[16px] lg:px-[10vw]">
            <div className="flex justify-between items-start gap-5 lg:gap-10">
              <div className="image-section h-56  md:h-[16rem] lg:h-[20rem] lg:m-0 hidden md:block">
                <img
                  src={require("../../images/Somfy_careers_recruitment_policy.jpg")}
                  alt=""
                  className=" h-full w-full rounded-2xl"
                />
              </div>
              <div className="policy-content-section w-[100%] lg:m-0 md:w-[45%] lg:w-[60%]">
                <h4 className="text-[#2B403B] font-[600] text-[2rem] lg:text-[2.25rem] mb-[15px]">
                  Recruitment privacy policy
                </h4>
                <div className="benefits-slider-text t-22 wysiwyg">
                  <p className="text-[1rem] lg:text-[1.37rem] font-[400] text-[#2B403B] mb-[10px]">
                    Decode takes care of your personal data at each stage of the
                    recruitment process and after its completion. You will find
                    all information in our recruitment privacy policy.
                  </p>
                  <p className="text-[1rem] lg:text-[1.37rem] font-[400] text-[#2B403B] mb-2">
                    All applications must be submitted in Smartrecruiters (all
                    paper and email applications will be automatically
                    rejected).
                  </p>
                  <button className="rounded-full border-[1px] bg-[#2B403B] px-3 py-1 lg:px-5 lg:py-4">
                    <span className="text-[#ffff] font-[600] text-[12px] lg:text-[16px]">
                      Find Out More
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
